<template>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="false" />
      <CRow class="justify-content-center">
        <CCol sm="3">
            <CCard>
                <CCardBody>
                    <CSelect label="Date Range" :value.sync="selectedOption" :options="selectOptions" @change="switchSelect($event)"  />
                </CCardBody>
            </CCard>
        </CCol>
      </Crow>
      <CRow>
        <CCol sm="6">
            <CCard>
            <CCardHeader>
                <CIcon name="cil-chart-pie" /><strong> By Subscription Product </strong>
            </CCardHeader>
            <CCardBody>
                <CChartDoughnut :datasets="byProductData" :labels="byProductLabels" />
            </CCardBody>
            </CCard>
        </CCol>
        <CCol sm="6">
            <CCard>
            <CCardHeader>
                <CIcon name="cil-chart-pie" /><strong> Subscription Data </strong>
            </CCardHeader>
            <CCardBody>
                <CChartDoughnut :datasets="statusData" :labels="statusLabels" />
            </CCardBody>
            </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
            <CCard>
            <CCardHeader>
                <CIcon name="cil-money" /><strong> Rebillings </strong>
            </CCardHeader>
            <CCardBody>
                <CChartLine :datasets="rebillingData" :labels="rebillingLabels" />
            </CCardBody>
            </CCard>
        </CCol>
      </CRow>
      
    </div>
  </template>

<script>
import { CChartDoughnut, CChartLine } from '@coreui/vue-chartjs';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Subscriptions",
  components: {
      Loading,
      CChartDoughnut,
      CChartLine
  },
  data() {
    return {
        isLoading: false,
        statusLabels: ['NA'],
        statusData: [
            {
                backgroundColor: [
                    '#41B883',
                    '#ed3617'
                ],
                data: [ 1 ]
            }
        ],
        byProductLabels: ['NA'],
        byProductData: [
            {
                backgroundColor: [
                    '#41B883',
                    '#00D8FF',
                    '#E46651'
                ],
                data: [ 1 ]
            }
        ],
        rebillingLabels: [ 0 ],
        rebillingData: [
            {
                label: 'Active',
                backgroundColor: 'rgb(0,216,255,0.9)',
                data: [ 1 ]
            },
            {
                label: 'Canceled',
                backgroundColor: 'rgb(228,102,81,0.9)',
                data: [ 1 ]
            }
        ],
        selectOptions: [
            { 
                value: 0, 
                label: 'All Time'
            },
            { 
                value: 28, 
                label: '28 Days (past month)'
            },
            { 
                value: 7, 
                label: '7 days (past week)'
            }
        ],
        selectedOption: 0
    };
  },
  computed: {
      
  },
  methods: {
    getSubReport() {
      this.isLoading = true;

      let url = this.$config.gmapi + "/Report/Subs/Status";

      if (this.selectedOption > 0) {
        url = url + "?days=" + this.selectedOption;
      }
      
      this.$http.get(url, {headers: this.$config.getHeaders()})
          .then((response) => {
              return response.json();
          })
          .then((jsonData) => {
              this.statusLabels = new Array(jsonData.length);
              this.statusData[0].data = new Array(jsonData.length);

              for (let i = 0; i < jsonData.length; i++) {
                  this.statusLabels[i] = jsonData[i].reportKey;
                  this.statusData[0].data[i] = jsonData[i].qty
              }
          })
          .catch(function (error) {
                this.isLoading = false;
                this.$root.$emit('onErr', error);
            })
          .finally(function () {
              this.getByProduct();
          });
      },
      getByProduct() {
        
        let url = this.$config.gmapi + "/Report/Subs/ByProduct";

        if (this.selectedOption > 0) {
            url = url + "?days=" + this.selectedOption;
          }   
        
        this.$http.get(url, {headers: this.$config.getHeaders(),})
          .then((response) => {
              return response.json();
          })
          .then((jsonData) => {
              this.byProductLabels = new Array(jsonData.length);
              this.byProductData[0].data = new Array(jsonData.length);

              for (let i = 0; i < jsonData.length; i++) {
                  this.byProductLabels[i] = jsonData[i].reportKey;
                  this.byProductData[0].data[i] = jsonData[i].qty
              }
          })
            .catch(function (error) {
                this.isLoading = false;
                this.$root.$emit('onErr', error);
            })
          .finally(function () {
            this.getRebillings()
          });
      },
      getRebillings() {
      this.$http.get(this.$config.gmapi + "/Report/Rebillings", {headers: this.$config.getHeaders(),})
          .then((response) => {
              return response.json();
          })
          .then((jsonData) => {
              this.rebillingLabels = [];
              this.rebillingData[0].data = [];
              this.rebillingData[1].data = [];

              for (let i = 0; i < jsonData.length; i++) {

                  if (this.rebillingLabels.indexOf(jsonData[i].secondary_qty) === -1) {
                      this.rebillingLabels.push(jsonData[i].secondary_qty);
                  }

                  var d = this.rebillingData.filter(function (item) {
                    return item.label == jsonData[i].reportKey;
                  });
                  d[0].data.push(jsonData[i].qty);
                  
              }
          })
          .finally(function () {
            this.isLoading = false;
          });
      },
      switchSelect(event) {
          this.selectedOption = event.target.value;
          this.getSubReport();
      }
  },
  created() {
      this.getSubReport();
  },
};
</script>